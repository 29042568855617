import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faEllipsisV, faEye, faTimes } from '@fortawesome/free-solid-svg-icons'
import './style.css'
import CustomTable from '../../Components/CustomTable'
import BASEURL from '../../Config/global'
import axios from 'axios'
import { useParams } from 'react-router'
import moment from 'moment'
import { Link } from 'react-router-dom'
const Reportings = () => {

    const [platform, setPlatformList] = useState([]);
    const {type, id} = useParams()

    useEffect(() => {
        fetchData()
    }, [])

    async function fetchData() {
        try {
            let url = `${BASEURL}/api/user/get_promo_main_users/${id}`;
            if(type === 'users'){
                url = `${BASEURL}/api/user/get_parent_childs/${id}`;
            }
            const response = await axios.get(url);
            if (response.data.error !== true) {
                // console.log(response.data.data);
                setPlatformList(response.data.data);
                // setPlatformList(response.data.count);
            } else {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const couponsHeader = [
        {
            key: "id",
            title: "Id",
        },
        {
            key: "firstname",
            title: "First Name",
        },

        {
            key: "secondname",
            title: "Second Name",
        },
        {
            key: "email",
            title: "Email",
        },
        {
            key: "dateofpayment",
            title: "Date of Payment",
        },
        {
            key: "paymentduedate",
            title: "Payment Due Date",
        },
        {
            key: "subscriptionamount",
            title: "Subscription Amount",
        },
        {
            key: "status",
            title: "Status",
        },
        {
            key: "actions",
            title: "Actions",
        },
    ];
    
    return (
        <DashboardLayout>
            <div className="row mb-3">
                <div className="col-lg-8 mb-2">
                    <h2 className="mainTitle">{type === 'users' ? 'Child Users' : 'Users'} </h2>
                </div>
                {/* <div className="col-lg-4 text-end mb-2">
                    <button
                        onClick={() => {
                            setEditId("")
                            setShowSwapModal(true)
                            setInputError({ error: false, text: '' })
                            setFormData({})
                            setIconImage(null)
                        }}
                        className={`customButton primaryButton `}
                    >
                        Add Platforms
                    </button>
                </div> */}
            </div>
            {

                <div className="col-12">
                    <CustomTable headers={couponsHeader}>
                        <tbody>
                            {platform && platform?.length > 0 &&
                                platform.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>

                                        <td style={{ width: '100px' }}>
                                            {
                                                item?.first_name
                                            }

                                        </td>
                                        <td >{item?.last_name}</td>
                                        <td >{item?.email}</td>
                                        <td >{item?.date_of_payment}</td>
                                        <td >{item?.payment_due_date}</td>
                                        {/* <td >{item?.subscription_amount}</td> */}
                                        <td>USD {item?.subscription_amount}</td>
                                        <td >{item?.status}</td>


                                        <td>
                                            <Dropdown className="tableDropdown">
                                                <Dropdown.Toggle
                                                    variant="transparent"
                                                    className="notButton classicToggle"
                                                >
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    align="end"
                                                    className="tableDropdownMenu"
                                                >
                                                    <Link
                                                        to={`/user-management/user-detail/${item.id}`}
                                                        className="tableAction"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faEye}
                                                            className="tableActionIcon"
                                                        />
                                                        View
                                                    </Link>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </CustomTable>
                </div>
            }

        </DashboardLayout>
    )
}

export default Reportings